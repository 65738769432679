<template>
  <div>
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="390px"
        lazy-src="/media/biz-header.jpg"
        src="/media/header2.jpg"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white">
                Manage Inventory
              </div>
              <div class="mt-5">
                <h4 class="text-white "></h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <md-card class="bg-success h-100">
              <md-card-content>
                <h5 class="card-category card-category-social text-white">
                  <i class="far fa-newspaper" /> Stock
                </h5>
                <h4 class="card-title text-white">
                  Keep track of your items inventory
                </h4>
                <p class="card-description text-white">
                  Import stock levels or export to your POS system via CSV file.
                  Available inventory is the On hand stock minus any pending
                  orders before they are invoiced.
                </p>

                <div class="card-stats text-center"></div>
              </md-card-content>
            </md-card>
          </div>
          <div class="col-lg-8">
            <md-card class="bg-primary h-100"> </md-card>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <v-toolbar color="grey" dark>
              <v-toolbar-title class="display-3"
                >Update Stock Levels</v-toolbar-title
              >

              <v-spacer></v-spacer>

              <v-btn color="primary" dark>
                Import
              </v-btn>
              <v-btn dark class="ml-4"> Export </v-btn>
              <v-spacer></v-spacer>
              <v-btn elevation="2" rounded color="success" href="#">
                Save</v-btn
              >
            </v-toolbar>
            <v-data-table
              :headers="headers"
              :items="manualItems"
              :items-per-page="50"
              class="elevation-1"
            >
              <template #item="row">
                <tr>
                  <td width="150px">{{ row.item.sku_id }}</td>
                  <td width="250px">
                    <span v-if="row.item.Name != 'default'">{{
                      row.item.Name
                    }}</span>
                    <span v-else-if="row.item.product != null">{{
                      row.item.product.name
                    }}</span>
                  </td>
                  <td width="250px">
                    <span v-if="row.item.product != null">{{
                      row.item.product.name
                    }}</span>
                  </td>

                  <td width="110px">
                    <span v-if="row.item.atp != 'default'">
                      {{ row.item.atp }}
                    </span>
                  </td>

                  <td width="110px">
                    <span v-if="row.item.qty != 'default'">
                      <v-text-field
                        v-model="row.item.qty"
                        filled
                        dense
                      ></v-text-field>
                    </span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";

import { mapActions } from "vuex";
import gql from "graphql-tag";

export default {
  mixins: [validationMixin],
  data() {
    return {
      items: [],

      manualItems: [],
      manualItem: "",
      manualQty: "",

      limit: 10,
      itemCurrentPage: 1,
      itemListCount: 0,
      headers: [
        {
          text: "SKU",
          value: "sku_id",
          align: "start",
        },
        {
          text: "Item Name",
          value: "Name",
        },
        {
          text: "Parent Product",
          value: "product.name",
        },
        {
          text: "Available",
          value: "atp",
        },
        {
          text: "On Hand",
          value: "qty",
        },
      ],
    };
  },
  apollo: {
    items: {
      query: gql`
        query Items(
          $limit: Int
          $start: Int
          $supplier_company: ID!
          $searchItem: String
          $searchSku: String
          $searchProduct: String
        ) {
          items: items(
            sort: "product:asc"
            limit: $limit
            start: $start
            where: {
              Name_contains: $searchItem
              sku_id_contains: $searchSku
              product: {
                supplier: { id: $supplier_company }
                name_contains: $searchProduct
              }
            }
          ) {
            id
            Name
            sku_id
            product {
              id
              name
            }
          }
        }
      `,
      variables() {
        return {
          searchItem: this.searchItem === "" ? undefined : this.searchItem,
          searchSku: this.searchSku === "" ? undefined : this.searchSku,
          searchProduct:
            this.searchProduct === "" ? undefined : this.searchProduct,
          supplier_company: this.$store.state.auth.user.company_relation,
          limit: this.limit,
          start: (this.itemCurrentPage - 1) * this.limit,
        };
      },
    },
  },
  created() {
    this.getItemWhere({
      where:
        "?_where[0][product.supplier]=" +
        this.$store.state.auth.user.company_relation,
    }).then((res) => {
      if (res.data != false) {
        this.itemListCount = res.data.length;
        this.manualItems = res.data;
      }
    });
  },

  methods: {
    ...mapActions([
      "getItemTotalCount",

      "getItemWhere",
      "getItemById",
      "getCompanyInfo",
    ]),
  },
};
</script>
<style></style>
